.Work {
  @include section(var(--theme-surface), var(--theme-on-surface));
  .container {
    border-bottom: 1px solid var(--theme-gray-400);
  }
  &__title {
    text-align: center;
    margin: 0 0 2rem;
  }
  &__timeline {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    // position: relative;
    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   left: 50%;
    //   width: 2px;
    //   background-color: var(--theme-on-surface);
    // }

    .WorkItem {
      position: relative;
      width: 50%;

      &:nth-child(even) {
        align-self: flex-end;
        text-align: left;
        padding-left: 1rem;
        @include breakpoint('tablet') {
          padding-left: 2rem;
        }
        &:before {
          left: -8px;
        }
        &:after {
          left: -2px;
        }
      }
      &:nth-child(odd) {
        padding-right: 1rem;
        text-align: right;
        @include breakpoint('tablet') {
          padding-right: 2rem;
        }
        &:before {
          right: -8px;
        }
        &:after {
          right: -2px;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        background-color: var(--theme-secondary);
        z-index: 1;
        border: 3px solid var(--theme-on-surface);
      }
      &:not(:last-child):after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 0.25rem;
        background-color: var(--theme-on-surface);
      }
      &__content {
        padding-bottom: 2rem;
        @include breakpoint('tablet') {
          padding-bottom: 3rem;
        }
        > p {
          margin: 0 0 0.375rem;
        }
        &__title {
          font-size: 1.25rem;
          font-weight: 600;
        }
        &__dates {
          font-size: 0.75rem;
          font-weight: 300;
          letter-spacing: 0.5px;
        }
        &__location {
          font-style: italic;
        }
      }
    }
  }
}
