.Education {
  @include section(var(--theme-surface), var(--theme-on-surface));
  &__title {
    text-align: center;
    margin: 0 0 2rem;
  }
  .School {
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    > p {
      margin: 0 0 0.375rem;
    }
    &__name {
      font-size: 1.25rem;
      font-weight: 600;
    }
    &__dates {
      font-size: 0.75rem;
      font-weight: 300;
      letter-spacing: 0.5px;
    }
    &__location {
      font-style: italic;
    }
  }
}
