:root[data-theme='light'] {
  @include spread-map($theme-map-light);
}
:root,
:root[data-theme='dark'] {
  @include spread-map($theme-map-dark);
}

:root.theme-transition,
:root.theme-transition * {
  transition: background-color 500ms, box-shadow 500ms !important;
  transition-delay: 0s !important;
}
