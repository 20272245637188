.Nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 0.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin-bottom: -49px;
  transition: background-color 200ms ease-in-out;
  @include breakpoint('sm-desktop') {
    margin-bottom: -51px;
  }
  &--scroll {
    // backdrop-filter: blur(4px);
    // -webkit-backdrop-filter: blur(4px);
    background: var(--theme-nav-background);
    transition: all 200ms ease-in-out;
  }

  &__brand {
    &__logo {
      width: 30px;
      height: 30px;
      @include breakpoint('sm-desktop') {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__toggler {
    border-style: none;
    background-color: transparent;
    border-radius: 4px;
    @include breakpoint('sm-desktop') {
      display: none;
    }
    &__icon {
      color: var(--theme-on-surface);
      width: 30px;
      height: 20px;
      position: relative;
      display: flex;
      > span {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: var(--theme-on-surface);
        border-radius: 2px;
        opacity: 1;
        transform: rotate(0deg);
        transition: all 200ms ease-in-out;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2) {
          top: 9px;
        }
        &:nth-child(3) {
          top: 18px;
        }
        &:nth-child(4),
        &:nth-child(5) {
          top: 9px;
        }
      }
      &--expanded {
        > span {
          transition: top 200ms;
          transition: opacity 200ms 150ms;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            top: 9px;
            opacity: 0;
          }
          &:nth-child(4),
          &:nth-child(5) {
            transition: transform 200ms 175ms;
          }
          &:nth-child(4) {
            transform: rotate(45deg);
          }
          &:nth-child(5) {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &__navbar {
    max-height: 0;
    transition: max-height 300ms ease-in-out;
    overflow: hidden;
    flex-basis: 100%;
    @include breakpoint('sm-desktop') {
      display: block;
      height: auto;
      overflow: visible;
      flex-basis: 0;
      max-height: none;
    }
    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @include breakpoint('sm-desktop') {
        flex-direction: row;
        column-gap: 0.5rem;
      }

      &__item {
        &__link {
          display: inline-block;
          color: var(--theme-on-surface);
          text-decoration: none;
          padding: 0.5rem;
          border-bottom: 1px solid transparent;
          transition: border-bottom 100ms ease-in-out;
          &:hover,
          &:focus {
            border-bottom: 1px solid var(--theme-primary);
            transition: all 200ms ease-in-out;
          }
          &--active {
            border-bottom: 2px solid var(--theme-primary) !important;
            font-weight: 600;
          }
        }
      }
      .Theme-switch {
        padding: 0.5rem;
        position: relative;
        &__checkbox {
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          margin: 0;
          position: absolute;
          &:checked + label:after {
            left: calc(100% + 3px);
            transform: translate(-100%, -25%);
          }
        }
        &__switch {
          display: flex;
          width: 3.75rem;
          height: 1.25rem;
          background-color: var(--theme-surface);
          border-radius: 0.775rem;
          position: relative;
          cursor: pointer;
          border: 2px solid var(--theme-gray-200);
          // transition: 0.5s;
          &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: -3px;
            width: 2rem;
            height: 2rem;
            border-radius: 100%;
            transition: 0.5s;
            background-repeat: no-repeat;
            background-position: center;
            transform: translate(0, -25%);
          }
        }
      }
    }
    &--expanded {
      max-height: 200px;
      flex-basis: 100%;
      transition: max-height 300ms ease-in-out;
      .Nav__navbar__list {
        flex-direction: column;
      }
    }
  }
}

:root[data-theme='dark'] {
  .Theme-switch__switch {
    &:after {
      // background-image: url('/images/dark-mode.svg');
      background-image: url('../../images/dark-mode.svg');
      background-color: var(--theme-secondary);
    }
  }
}

:root[data-theme='light'] {
  .Theme-switch__switch {
    &:after {
      // background-image: url('/images/light-mode.svg');
      background-image: url('../../images/light-mode.svg');
      background-color: var(--theme-primary);
    }
  }
}
