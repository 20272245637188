.Projects {
  @include section(var(--theme-surface), var(--theme-on-surface));
  overflow: hidden;
  > .container {
    border-bottom: 1px solid var(--theme-gray-400);
  }
  &__title {
    text-align: center;
    margin: 0 2rem;
  }
  &__content {
  }
}

.Project {
  padding: 3rem 0;
  border-bottom: 1px solid var(--theme-gray-400);
  display: flex;
  flex-direction: column;
  @include breakpoint('sm-desktop') {
    flex-direction: row;
    column-gap: 1rem;
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
  @include breakpoint('tablet') {
    padding: 5rem 0;
  }
  &__info {
    display: flex;
    flex-direction: column;
    @include breakpoint('sm-desktop') {
      width: 40%;
    }
    &__title {
      margin: 0 0 1rem;
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: -0.5px;
      &--subtitle {
        color: var(--theme-secondary);
      }
    }
    &__description {
      margin: 0 0 1rem;
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 300;
    }
    &__tags {
      margin: 0 0 1.5rem;
      list-style-type: none;
      padding: 0;
      display: flex;
      font-size: 0.875rem;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      font-weight: 600;
      &__item {
        &:not(:first-child):before {
          content: '•';
          color: var(--theme-secondary);
          margin: 0 0.75rem;
          font-weight: 900;
        }
      }
    }
    &__links {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
      @include breakpoint('tablet') {
        flex-direction: row;
        column-gap: 1rem;
      }
      &__link {
        @include button(var(--theme-primary-hover));
        box-shadow: 6px 6px 12px var($--theme-box-shadow-surface-darker),
          -6px -6px 12px var($--theme-box-shadow-surface-lighter);
        // box-shadow: -5px -5px 10px var(--theme-box-shadow-surface-lighter),
        //   5px 5px 10px var(--theme-box-shadow-surface-darker);
        font-weight: 500;
        margin: 0 0 1rem;
        font-size: 1.25rem;
        @include breakpoint('tablet') {
          // margin: 0;
          width: 150px;
        }
        &--primary {
          background-color: var(--theme-primary);
          color: var(--theme-on-primary);
          border-color: var(--theme-primary);
        }
        &--default {
          border-color: var(--theme-on-surface);
          color: var(--theme-on-surface);
          &:hover {
            // border-color: var(--theme-gray-400);
            background-color: var(--theme-gray-400);
            // background-color: initial;
          }
        }
      }
    }
  }
  &__picture {
    // position: relative;
    transition: transform 0.1s;

    @include breakpoint('sm-desktop') {
      width: 60%;
    }
    > a {
      display: inline-block;
      box-shadow: 0px 10px 25px -5px var(--theme-gray-400);

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
}
