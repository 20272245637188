.Skills {
  @include section(rgb(187, 134, 252), var(--theme-on-primary));
  background: linear-gradient(135deg, var(--theme-gradient-from) 30%, var(--theme-gradient-to) 85%);
  > .container {
    @include breakpoint('tablet') {
      max-width: 1180px;
    }

    @include breakpoint('sm-desktop') {
      padding: 12rem 2rem;
    }
  }
  &__title {
    margin: 0 0 2rem;
    font-size: 1.5rem;
    text-align: center;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    justify-content: center;
    @include breakpoint('tablet') {
      row-gap: 1.5rem;
      column-gap: 1.5rem;
    }
  }
}

.Skill {
  flex-grow: 0;
  transition: all 250ms ease;

  &:nth-child(4n + 1) {
    &:hover {
      transform: rotate(-5deg) scale(1.05);
      svg {
        transform: scale(1.2) translateY(-10px);
      }
    }
  }
  &:nth-child(4n + 2) {
    &:hover {
      transform: scale(1.15);
      svg {
        transform: rotate(360deg) scale(1.1);
      }
    }
  }
  &:nth-child(4n + 3) {
    &:hover {
      transform: translateY(-10px);
      svg {
        transform: translateY(-10px) rotateY(360deg);
      }
    }
  }
  &:nth-child(4n + 4) {
    &:hover {
      transform: rotate(5deg);
      svg {
        transform: translateX(10px) rotate(10deg) scale(1.1);
      }
    }
  }

  &__card {
    height: 60px;
    width: 130px;
    border: 1px solid var(--theme-gray-100);
    border-radius: 4px;
    padding: 1rem;
    display: flex;
    font-size: 1.25rem;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
    column-gap: 0.25rem;
    @include breakpoint('mobile') {
      height: 60px;
      width: 150px;
      @include breakpoint('lg-mobile') {
        width: 240px;
        height: 80px;
        @include breakpoint('tablet') {
          width: 200px;
          height: 150px;
          flex-direction: column;
        }
      }
    }

    > svg {
      font-size: 1.75rem;
      transition: all 500ms ease;
      @include breakpoint('tablet') {
        font-size: 3rem;
      }
    }
    &__name {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}
