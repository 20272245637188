// MEDIA QUERIES

/*
  576px: mobile
  768: tablet
  1200+: desktop
*/

@mixin breakpoint($breakpoint) {
  // mobile
  @if $breakpoint == sm-mobile {
    @media (min-width: 320px) {
      @content;
    }
  }
  @if $breakpoint == mobile {
    @media (min-width: 365px) {
      @content;
    }
  }
  @if $breakpoint == lg-mobile {
    @media (min-width: 576px) {
      @content;
    }
  }
  // tablet
  @if $breakpoint == tablet {
    @media (min-width: 768px) {
      @content;
    }
  }
  // desktop
  @if $breakpoint == sm-desktop {
    @media (min-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

// theme spreader
@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

// button
@mixin button($hover-color) {
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    background-color: $hover-color;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin section($background-color, $color) {
  background-color: $background-color;
  color: $color;
}

@mixin animate($duration, $easing, $delay, $iteration, $direction, $fill, $state, $name) {
  animation: $duration $easing $delay $iteration $direction $fill $state $name;
}

// animations
@keyframes jump {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }

  1% {
    transform: translateY(-50%);
  }

  30% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-35%);
  }

  65% {
    transform: translate(0);
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-up-fade-in {
  from {
    opacity: 0;
    transform: translateY(25px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(50%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes fade-in-anim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
