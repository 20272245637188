.Hero {
  position: relative;
  color: var(--theme-on-surface);
  background-color: var(--theme-background);
  height: 110vh;
  overflow: hidden;
  // background-image: url('/images/topography.svg');

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--theme-background) 90%);
  }

  .img-bg {
    position: absolute;
    inset: 0;
    opacity: 0;
    transform: scale(1.25);
    transition: all 1s ease;

    &.show {
      opacity: 1;
      transform: scale(1);
    }
  }

  > .container {
    height: 100%;
    position: relative;
    z-index: 1;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 0;
    // align-self: center;
    justify-content: center;
    height: 100%;
    margin-top: -50px;
    @include breakpoint('tablet') {
      padding: 0 1rem 0;
    }

    &__title {
      font-size: 2rem;
      line-height: 1.5;
      // overflow: hidden;
      @include breakpoint('sm-desktop') {
        font-size: 3.5rem;
      }
      margin: 0 0 5rem;
      font-weight: 800;
      &--underline {
        position: relative;
        display: inline-block;
        // text-decoration: underline;
        // text-decoration-color: var(--theme-secondary);
        &:before {
          content: '';
          position: absolute;
          left: 0;
          // right: 0%;
          bottom: 6px;
          height: 4px;
          background-color: var(--theme-secondary);
          z-index: -1;
          width: 0%;
          border-radius: 4px;
          animation-duration: 1.875s;
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-delay: 875ms;
          animation-iteration-count: initial;
          animation-direction: normal;
          animation-fill-mode: forwards;
          animation-name: underlineLtr;
        }
      }
      &--accent {
        color: var(--theme-primary);
        font-weight: 900;
        position: relative;
        display: inline-block;

        &:before {
          content: '';
          position: absolute;
          inset: 0;
          background: var(--theme-primary);
          animation-duration: 1.25s;
          animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
          animation-delay: 1500ms;
          animation-iteration-count: initial;
          animation-direction: normal;
          animation-fill-mode: forwards;
          animation-name: revealLtr;
          transform-origin: right;
          border-radius: 5px;
        }
      }
    }
    &__button {
      display: block;
      position: relative;
      @include button(transparent);
      border-color: var(--theme-primary);
      color: var(--theme-primary);
      font-weight: 800;
      font-size: 1.25rem;
      border-width: 2px;
      width: 66%;
      max-width: 200px;
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      background: rgba(255, 255, 255, 0.05);
      overflow: hidden;
      transition: color 1s;
      padding: 0.5rem;
      // background: var(--theme-background);
      box-shadow: 10px 10px 20px var(--theme-box-shadow-bg-darker),
        -10px -10px 20px var(--theme-box-shadow-bg-lighter);
      @include breakpoint('mobile') {
        padding: 0.5rem 1rem;
        @include breakpoint('tablet') {
          width: 200px;
        }
      }

      &:hover {
        color: var(--theme-on-primary);
        transition: all 1s;
        &:before {
          left: -10px;
          transform: rotate(180deg);
          transition: all 1.5s;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: -200%;
        left: -130%;
        right: 0;
        bottom: 0;
        height: 210px;
        width: 210px;
        border-radius: 35%;
        background-color: var(--theme-primary);
        transform: rotate(0deg);
        z-index: -1;
        transition: all 1.5s;
        @include breakpoint('mobile') {
          left: -110%;
          height: 210px;
          width: 210px;
        }
      }
    }
  }
}

@keyframes underlineLtr {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes revealLtr {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
}
