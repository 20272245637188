.Footer {
  @include section(var(--theme-background), var(--theme-primary));
  display: flex;
  flex-direction: column;
  padding: 4rem;
  @include breakpoint('tablet') {
    padding: 5rem;
  }
  &__networks {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    .Network {
      &__link {
        color: var(--theme-primary);
        font-size: 1.75rem;
        transform: translateY(0px);
        &:hover {
          color: var(--theme-primary-hover);
          transform: translate(0, -10px);
          transition: 500ms;
        }
      }
    }
  }
  &__copyright {
    align-self: center;
    font-weight: 200;
    letter-spacing: 1.1px;
    font-size: 0.875rem;
  }
}

:root[data-theme='light'] {
  .Footer {
    &__copyright {
      font-weight: 400;
    }
  }
}
